import { menusHeader } from '@/configs';
import React from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import Dropdown from 'rc-dropdown';
import { UrlLink } from '@/configs/urlLink';

const DesktopMenu = () => {
  const router = useRouter();
  const { idBlogSlug } = router.query;

  const isActive = (pathNameMenu: string) => {
    if (!router.asPath) return false;

    if (pathNameMenu === menusHeader[0].pathname) {
      return router.asPath === menusHeader[0].pathname;
    }

    if (idBlogSlug) {
      return pathNameMenu === menusHeader[5].pathname;
    }
    // if (pathNameMenu === menusHeader[6].pathname) {
    //   return router.asPath === menusHeader[6].pathname;
    // }
    else {
      return router.asPath.includes(pathNameMenu);
    }
  };
  return (
    <div className="hidden gap-x-1 font-semibold text-white lg:flex ">
      {menusHeader.map(item => {
        if (item.pathname === UrlLink.knowledge.knowledge) {
          return (
            <Dropdown
              key={item.id}
              trigger={['click']}
              getPopupContainer={trigger => trigger.parentNode as HTMLElement}
              overlay={
                <div className="text-PC-Subtitle-Small mt-[-1px] flex flex-col bg-white font-sans text-black shadow">
                  <Link href={UrlLink.knowledge.knowledge}>
                    <div className="hover:text-primary-eastern p-2 font-sans text-sm hover:bg-gray-300">
                      Video
                    </div>
                  </Link>
                  <Link href={UrlLink.knowledge.series}>
                    <div className="text-PC-Subtitle-Small hover:text-primary-eastern p-2 font-sans text-sm hover:bg-gray-300">
                      Bài viết
                    </div>
                  </Link>
                </div>
              }
            >
              <div className="text-PC-Subtitle-Small hover:text-primary-eastern flex items-center pr-10">
                <div>
                  <button
                    className={`hover:text-primary-eastern  text-black ${
                      isActive(item.pathname) && 'text-primary-eastern'
                    } ${
                      isActive(UrlLink.knowledge.series) &&
                      'text-primary-eastern'
                    }`}
                  >
                    Kiến thức
                  </button>
                </div>

                <div className="flex h-6 w-6 items-center justify-center">
                  <svg
                    width="10"
                    height="5"
                    viewBox="0 0 10 5"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5 5L0 0H10L5 5Z"
                      fill={`${
                        isActive(UrlLink.knowledge.knowledge)
                          ? '#047D95'
                          : '#000000'
                      }`}
                    />
                  </svg>
                </div>
              </div>
            </Dropdown>
          );
        }
        return (
          <div key={item.id}>
            <Link
              href={item.pathname}
              className={`text-PC-Subtitle-Small relative flex  h-full items-center justify-start px-10 pl-6 font-sans lg:justify-center lg:pl-0`}
            >
              <p
                className={`text-neutral-dark hover:text-primary-eastern leading-[76px] duration-300 ${
                  isActive(item.pathname) && 'text-primary-eastern'
                }`}
              >
                {item.title}
              </p>
            </Link>
          </div>
        );
      })}
    </div>
  );
};

export default DesktopMenu;
